import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../services/users/users.service';
import { ComodatosService } from '../../services/comodatos/comodatos.service';
import { Remision } from 'src/app/models/Remision';
import { RemisionDetalle } from 'src/app/models/RemisionDetalle';
import { Direccionc } from 'src/app/models/Direccion';
import { AclService } from 'ng2-acl/dist';

@Component({
  selector: 'app-comodatos',
  templateUrl: './comodatos.component.html',
  styleUrls: ['./comodatos.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class ComodatosComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  modaldetalleRef: BsModalRef;
  modalordenRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };
  configLarge = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'Custom-size-modal'
  };
  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  lista: any[] = [];
  model: Remision = new Remision();
  modeldetalle: RemisionDetalle = new RemisionDetalle();
  detalles: RemisionDetalle[] = [];
  clienteDireccion: Direccionc = new Direccionc();
  clienteDirecciones: Direccionc[] = [];

  from: NgbDateStruct;
  to: NgbDateStruct;
  cliente: any;
  vendedor: any;
  sucursal: any;
  almacen: any;
  folio = '';
  folioSucursal = '';
  vendedores: any[] = [];
  clientes: any[] = [];
  sucursales: any[] = [];
  almacenes: any[] = [];
  metodospago: any[] = [];
  cfdis: any[] = [];
  productos: any[] = [];
  productoskit: any[] = [];
  showguardar = false;
  iva: number;
  total: number;
  descuento: number;
  StatusList = [{ ID: 1, Descripcion: 'Contado' }, { ID: 2, Descripcion: 'Credito' }, { ID: 3, Descripcion: 'Por Definir' }];
  radioProducto: any;
  activeNormal: any;
  activeModelo: any;
  activeKit: any;

  constructor(private _userService: UsersService, private _comodatoService: ComodatosService, private modalService: BsModalService, private toastr: ToastrService, public aclService: AclService) {
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.onBuscar();
    this.getCombos();
  }

  onBuscar() {
    let from = '';
    let to = '';
    if (this.from !== undefined && this.to !== undefined) {
      from = `${this.from.year}-${this.from.month}-${this.from.day}`;
      to = `${this.to.year}-${this.to.month}-${this.to.day}`;
    }

    this._comodatoService.getLista(from, to, this.sucursal, this.almacen, this.cliente, this.vendedor, this.folioSucursal, this.folio).subscribe(
      (data: any) => {
        this.lista = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onSubmit(FormData: any) {
    if (FormData.valid) {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Esta seguro que quiere guardar Comodato.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Guardar!',
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false
      }).then((result) => {
        if (result.value) {
              if (this.model.Status === undefined || this.model.Status === null || this.model.Status === '') {
                this.model.Status = 'B';
              }

              const model = {
                model: this.model,
                detalles: this.detalles
              };
              this._comodatoService.guardar(model)
            .subscribe(
              success => {
                  this.toastr.success('Comodato guardado con exito.', 'Guardado!');
                  this.onBuscar();
                  FormData.resetForm();
                  this.modalRef.hide();
              },
              error => {
                this.toastr.error(error.message, 'Error!');
              });
        }
      });
    }
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new Remision();
    this.detalles = [];
    this.showguardar = true;
    if (id <= 0) {
      this.modalRef = this.modalService.show(template, this.configLarge);
    } else {
      this._comodatoService.getRemision(id)
    .subscribe(
      data => {
        this.model = data.model;
        this.detalles = data.detalles;
        if (this.model.Fecha) {
          this.model.Fecha = new Date(this.model.Fecha);
          this.model.SelectedDate = this.getDateStructFromDate(this.model.Fecha);
        }

        if (this.model.Status === 'B') {
          this.showguardar = true;
        } else {
          this.showguardar = false;
        }

        this.modalRef = this.modalService.show(template, this.configLarge);
      },
      error => this.toastr.error(error.message, 'Error!') );
    }
  }

  onFechaSolChanged(selectedDate: NgbDateStruct) {
    this.model.Fecha = this.getDateFromDateStruct(selectedDate);
  }

  getCombos() {
    this._comodatoService.getCombos()
      .subscribe(
        data => {
          this.clientes = data.clientes;
          this.vendedores = data.vendedores;
          this.productos = data.productos;
          this.sucursales = data.sucursales;
          this.almacenes = data.almacenes;
          this.cfdis = data.cfdis;
          this.metodospago = data.metodospago;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  onShowSolDetalle(model: RemisionDetalle, template: TemplateRef<any>) {
    this.modeldetalle = new RemisionDetalle();
    if (model) {
      this.modeldetalle = model;
    }
    this.modaldetalleRef = this.modalService.show(template, this.configMd);
  }

  onDeleteSolDetalle(model: RemisionDetalle) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar detalle, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        const Index = this.detalles.findIndex((Item: RemisionDetalle) => Item.ID === model.ID);
        if (Index !== -1) {
            this.detalles.splice(Index, 1);
        }
        Swal.fire({
          title: 'Eliminado!',
          text: 'Detalle a sido eliminado con exito.',
          type: 'success',
          confirmButtonText: 'Aceptar'
        });
      }
    });
  }


  onSubmitDetalle(FormData: any) {
    if (FormData.valid) {
      if (this.modeldetalle.esKit === 'Y') {

        this._comodatoService.getProductos(this.modeldetalle.ID_Producto)
      .subscribe(
        data => {
          const kits = data;
          kits.forEach(prod => {
            let modeldetalle_tmp = new RemisionDetalle();
            const producto = this.productos.filter(
              item => item.ID === prod.IDProductoDetalle
            )[0];
            modeldetalle_tmp.InfoProducto = producto.PRD_Nombre;
            modeldetalle_tmp.Cantidad = this.modeldetalle.Cantidad * producto.KIT_Cantidad;
            modeldetalle_tmp.PrecioUnitario = producto.PRD_Costo;
            modeldetalle_tmp.Subtotal = modeldetalle_tmp.Cantidad * modeldetalle_tmp.PrecioUnitario;
            modeldetalle_tmp.Total = modeldetalle_tmp.Cantidad * modeldetalle_tmp.PrecioUnitario;
            modeldetalle_tmp.ID_Producto = prod.IDProductoDetalle;
            this.detalles.push(modeldetalle_tmp);
          });
          this.toastr.success('Producto agregado con exito.', 'Agregado!');
          this.modaldetalleRef.hide();
        },
        error => this.toastr.error(error.message, 'Error!') );

      } else {
        this.modeldetalle.Total = this.modeldetalle.Cantidad * this.modeldetalle.PrecioUnitario;
        this.detalles.push(this.modeldetalle);
        this.toastr.success('Producto agregado con exito.', 'Agregado!');
        this.modaldetalleRef.hide();
      }
    }
  }

  onProductoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }

    this.modeldetalle.InfoProducto = selectedValue.Nombre;
    this.modeldetalle.PrecioUnitario = selectedValue.Costo;
    this.modeldetalle.esKit = selectedValue.esKit;
  }

  onClienteChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }

    this._comodatoService.getDirecciones(selectedValue.ID)
      .subscribe(
        data => {
          this.clienteDirecciones = data;
        },
        error => this.toastr.error(error.message, 'Error!') );

    this._comodatoService.getDireccionDefault(selectedValue.ID)
        .subscribe(
          data => {
            this.clienteDireccion = data;

            this.model.Domicilio = this.clienteDireccion.Direccion;
            this.model.Ciudad = selectedValue.Ciudad;
            this.model.RFC = selectedValue.Rfc;
            this.model.ID_DireccionCliente = this.clienteDireccion.ID;

          },
          error => this.toastr.error(error.message, 'Error!') );
  }

  onGetClave(model: RemisionDetalle) {
    const producto = this.productos.filter(
      item => item.ID === model.ID_Producto
    )[0];
    return producto.Codigo;
  }

  getDateFromDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  getDateStructFromDate(date: Date): NgbDateStruct {
    const dateStruct: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };

    return dateStruct;
  }

  onImprimir(id: number) {
    const model = {
      id
    };
    this._comodatoService.imprimir(model).subscribe(
        (data: any ) => {
          const fileblob = new Blob([data], {type: 'application/pdf'});
          if (navigator.appVersion.toString().indexOf('.NET') > 0) {
              window.navigator.msSaveOrOpenBlob(fileblob, 'CoverSheet.pdf');
          } else {
              const fileURL = URL.createObjectURL(fileblob);
              window.open(fileURL);
          }
        },
        (error) => {
          this.toastr.error(error.message, 'Error!');
        }
      );
  }

  async onProcesar(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que desea procesar la comodato, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Procesar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then(async (result) => {
      if (result.value) {
        const modelAut = {
          id
        };

        this._comodatoService.procesar(modelAut)
        .subscribe(
          success => {
            Swal.fire({
              title: 'Procesada!',
              text: 'Venta procesada con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
            this.onBuscar();
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
      }
    });
  }

  onCancelar(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que desea cancelar la comodato, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Cancelar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then(async (result) => {
      if (result.value) {

        const modelAut = {
          id
        };

        this._comodatoService.cancelar(modelAut)
        .subscribe(
          success => {
            Swal.fire({
              title: 'Cancelada!',
              text: 'Comodato cancelado con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
            this.onBuscar();
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });

      }
    });
  }

  onGetSubTotal() {
    let subtotal = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.detalles.length; index++) {
      const element = this.detalles[index];
      subtotal += element.Cantidad * element.PrecioUnitario;
    }
    this.iva = (subtotal * 0.16);
    this.total = subtotal + this.iva;
    this.model.Subtotal = subtotal;
    this.model.Impuestos = this.iva;
    this.model.Total = this.total;

    return subtotal;
  }

  OnUpdateTotal() {
    this.modeldetalle.Subtotal = (this.modeldetalle.Cantidad * this.modeldetalle.PrecioUnitario);
    this.modeldetalle.Total = this.modeldetalle.Cantidad * this.modeldetalle.PrecioUnitario;
  }

  onRadioProdChanged() {
    if (this.radioProducto === 1) {
      this.activeNormal = true;
      this.activeModelo = false;
      this.activeKit = false;
      this.modeldetalle.esKit = 'N';
    } else if (this.radioProducto === 2) {
      this.activeNormal = false;
      this.activeModelo = true;
      this.activeKit = false;
      this.modeldetalle.esKit = 'N';
    } else {
      this.activeNormal = false;
      this.activeModelo = false;
      this.activeKit = true;
      this.modeldetalle.esKit = 'Y';
    }
  }

  onProductoKitChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.modeldetalle.InfoProducto = selectedValue.Nombre;
    this.modeldetalle.PrecioUnitario = selectedValue.Costo;
    this.modeldetalle.esKit = selectedValue.esKit;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.Codigo.toLocaleLowerCase().indexOf(term) > -1 ||
    item.Nombre.toLocaleLowerCase().indexOf(term) > -1 ||
    (item.Codigo + ' - ' + item.Nombre).toLocaleLowerCase().indexOf(term) > -1;
  }

}



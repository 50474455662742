import { Component, OnInit, ViewChild, TemplateRef, ContentChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../services/users/users.service';
import { Router } from '@angular/router';
import { ProductosService } from '../../services/productos/productos.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-merge-producto',
  templateUrl: './merge-producto.component.html',
  styleUrls: ['./merge-producto.component.css']
})
export class MergeProductoComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  modalDevRef: BsModalRef;

  @ViewChild('templateVenta') templateVenta;
  @ViewChild('templateDevolucion') templateDevolucion;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };
  configLarge = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'Custom-size-modal'
  };
  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  idproductoprincipal: any;
  idproductosecundario: any;

  productos: any[] = [];

  constructor(private _userService: UsersService, private _productoService: ProductosService, private modalService: BsModalService, private toastr: ToastrService, public router: Router) {
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.getCombos();
  }

  getCombos() {
    this._productoService.getCombosMerge()
      .subscribe(
        data => {
          this.productos = data.productos;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  onSubmit(FormData: any) {
    if (FormData.valid) {
      if (this.idproductoprincipal > 0 && this.idproductosecundario > 0) {
        Swal.fire({
          title: 'Esta seguro?',
          text: 'Esta seguro que desea guardar combinar productos?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, Combinar!',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        }).then(async (result) => {
          if (result.value) {
            const model = {
              idproductoprincipal: this.idproductoprincipal,
              idproductosecundario: this.idproductosecundario
            };
            this._productoService.mergeProducto(model)
            .subscribe(
              success => {
                  this.toastr.success('Combinacion de Producto con exito.', 'Merge!');
                  FormData.resetForm();
                  this.idproductoprincipal = null;
                  this.idproductosecundario = null;
              },
              error => {
                this.toastr.error(error.message, 'Error!');
              });
          }
        });
      } else {
        this.toastr.error('Favor de introducir campos necesarios', 'Error!');
      }
    }
  }

  onCerrar() {
    this.router.navigate(['/dashboard']);
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.Codigo.toLocaleLowerCase().indexOf(term) > -1 ||
    item.Nombre.toLocaleLowerCase().indexOf(term) > -1 ||
    (item.Codigo + ' - ' + item.Nombre).toLocaleLowerCase().indexOf(term) > -1;
  }
}






import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { AppSettings } from '../../models/app-settings';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class TraspasosService {

  private _url = `${AppSettings.API_ENDPOINT}/Traspasos`;
  private _getLista = `${this._url}/Lista`;
  private _getListaTransito = `${this._url}/ListaTransito`;
  private _getGetTraspaso = `${this._url}/GetTraspaso`;
  private _guardar = `${this._url}/Guardar`;
  private _enviar = `${this._url}/Enviar`;
  private _cancelar = `${this._url}/Cancelar`;
  private _recibir = `${this._url}/Recibir`;
  private _getCombos = `${this._url}/ListasCombos`;
  private _getGetProductosKit = `${this._url}/GetProductosKit`;
  private _eliminar = `${this._url}/Eliminar`;
  private _getImprimir = `${this._url}/Imprimir`;

  constructor(public _http: HttpClient, private _userService: UsersService) {
    this._userService.loadStorage();
  }

  getLista(from: String, to: String, almacenorigen: number, almacendestino: number, estatus: String, folioazul: String, folio: String): Observable<any[]> {
    const params = new HttpParams()
      .set('from', from.toString())
      .set('to', to.toString())
      .set('almacenorigen', String(almacenorigen))
      .set('almacendestino', String(almacendestino))
      .set('estatus', String(estatus))
      .set('folioazul', String(folioazul))
      .set('folio', String(folio));

    return this._http.get<any[]>(this._getLista, { params: params, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getListaTransito(from: String, to: String, almacenorigen: number, almacendestino: number, folioazul: String, folio: String): Observable<any[]> {
    const params = new HttpParams()
      .set('from', from.toString())
      .set('to', to.toString())
      .set('almacenorigen', String(almacenorigen))
      .set('almacendestino', String(almacendestino))
      .set('folioazul', String(folioazul))
      .set('folio', String(folio));

    return this._http.get<any[]>(this._getListaTransito, { params: params, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getCombos(): Observable<any> {
    return this._http.get<any>(this._getCombos, { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getTraspaso(id: number): Observable<any>  {
    return this._http.get<any>(`${this._getGetTraspaso}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  guardar(model: any): Observable<any> {
    return this._http.post<any>(`${this._guardar}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  enviar(model: any): Observable<any> {
    return this._http.post<any>(`${this._enviar}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  recibir(model: any): Observable<any> {
    return this._http.post<any>(`${this._recibir}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  cancelar(model: any): Observable<any> {
    return this._http.post<any>(`${this._cancelar}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getProductos(id: number): Observable<any>  {
    return this._http.get<any>(`${this._getGetProductosKit}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  eliminar(model: any): Observable<any> {
    return this._http.post<any>(`${this._eliminar}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  imprimir(model) {
    return  this._http.post(`${this._getImprimir}`, model, {responseType: 'arraybuffer', headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }
  
  // Handdle Error methor for observale
  private handleError(err: HttpErrorResponse) {
    return throwError(err.error);
  }
}

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../services/users/users.service';
import { DescomposicionService } from '../../services/descomposicion/descomposicion.service';
import { ProductoDescomposicion } from '../../models/ProductoDescomposicion';
import { ProductoDescomposicionDetalle } from '../../models/ProductoDescomposicionDetalle';

@Component({
  selector: 'app-descomposicion-producto',
  templateUrl: './descomposicion-producto.component.html',
  styleUrls: ['./descomposicion-producto.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class DescomposicionProductoComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  modaldetalleRef: BsModalRef;
  modalordenRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };
  configLarge = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'Custom-size-modal'
  };
  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  lista: any[] = [];
  model: ProductoDescomposicion = new ProductoDescomposicion();
  subtotal = 0;
  modeldetalle: ProductoDescomposicionDetalle = new ProductoDescomposicionDetalle();
  detalles: ProductoDescomposicionDetalle[] = [];

  from: NgbDateStruct;
  to: NgbDateStruct;
  idproducto: any;
  almacenid: any;
  folio = '';

  almacenes: any[] = [];
  productos: any[] = [];
  showguardar = true;

  constructor(private _userService: UsersService, private _descomposicionService: DescomposicionService, private modalService: BsModalService, private toastr: ToastrService) {
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.onBuscar();
    this.getCombos();
  }

  onBuscar() {
    let from = '';
    let to = '';
    if (this.from !== undefined && this.to !== undefined) {
      from = `${this.from.year}-${this.from.month}-${this.from.day}`;
      to = `${this.to.year}-${this.to.month}-${this.to.day}`;
    }

    this._descomposicionService.getLista(from, to, this.idproducto, this.almacenid, this.folio).subscribe(
      (data: any) => {
        this.lista = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onSubmit(FormData: any) {
    if (FormData.valid) {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Esta seguro que quiere guardar Descomposición.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Guardar!',
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false
      }).then((result) => {
        if (result.value) {
              this.model.ID_Usuario = this._userService.user.ID;
              if (this.model.Status === undefined || this.model.Status === null || this.model.Status === '') {
                this.model.Status = 'G';
              }

              const model = {
                model: this.model,
                detalles: this.detalles
              };
              this._descomposicionService.guardar(model)
            .subscribe(
              success => {
                  this.toastr.success('Descomposición guardado con exito.', 'Guardado!');
                  this.onBuscar();
                  FormData.resetForm();
                  this.modalRef.hide();
              },
              error => {
                this.toastr.error(error.message, 'Error!');
              });
        }
      });
    }
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new ProductoDescomposicion();
    this.detalles = [];
    this.showguardar = true;
    if (id <= 0) {
      this.modalRef = this.modalService.show(template, this.configLarge);
    } else {
      this._descomposicionService.getDescomposicion(id)
    .subscribe(
      data => {
        this.model = data.model;
        this.detalles = data.detalles;
        if (this.model.Fecha) {
          this.model.Fecha = new Date(this.model.Fecha);
          this.model.SelectedDate = this.getDateStructFromDate(this.model.Fecha);
        }

        if (this.model.Status !== 'G') {
          this.showguardar = false;
         }

        this.modalRef = this.modalService.show(template, this.configLarge);
      },
      error => this.toastr.error(error.message, 'Error!') );
    }
  }

  onFechaSolChanged(selectedDate: NgbDateStruct) {
    this.model.Fecha = this.getDateFromDateStruct(selectedDate);
  }

  getCombos() {
    this._descomposicionService.getCombos()
      .subscribe(
        data => {
          this.productos = data.productos;
          this.almacenes = data.almacenes;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  onShowSolDetalle(model: ProductoDescomposicionDetalle, template: TemplateRef<any>) {
    this.modeldetalle = new ProductoDescomposicionDetalle();
    if (model) {
      this.modeldetalle = model;
    } 
    this.modaldetalleRef = this.modalService.show(template, this.configMd);
  }

  onDeleteSolDetalle(model: ProductoDescomposicionDetalle) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar detalle, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        const Index = this.detalles.findIndex((Item: ProductoDescomposicionDetalle) => Item.ID === model.ID);
        if (Index !== -1) {
            this.detalles.splice(Index, 1);
        }
        Swal.fire({
          title: 'Eliminado!',
          text: 'Detalle a sido eliminado con exito.',
          type: 'success',
          confirmButtonText: 'Aceptar'
        });
      }
    });
  }


  onSubmitDetalle(FormData: any) {
    if (FormData.valid) {
      this.detalles.push(this.modeldetalle);
      this.toastr.success('Producto agregado con exito.', 'Agregado!');
      this.modaldetalleRef.hide();
    }
  }

  onProductoBaseChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.modeldetalle.Costo = selectedValue.Costo;
    this.model.ID_ProductoBase = selectedValue.ID;
    this.model.CostoBase = selectedValue.Costo;
    this.model.Existencia = selectedValue.Cantidad;
    this.detalles = [];
    this.onUpdateCostoTotal();
  }

  onUpdateCostoTotal() {
    this.model.CostoTotal = this.model.CostoBase * this.model.Cantidad;
  }

  onProductoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.modeldetalle.Costo = selectedValue.Costo;
  }

  onGetClave(model: ProductoDescomposicionDetalle) {
    const producto = this.productos.filter(
      item => item.ID === model.ID_ProductoNuevo
    )[0];
    return producto.Codigo;
  }

  onGetInfoProducto(model: ProductoDescomposicionDetalle) {
    const producto = this.productos.filter(
      item => item.ID === model.ID_ProductoNuevo
    )[0];
    return producto.Nombre;
  }

  getDateFromDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  getDateStructFromDate(date: Date): NgbDateStruct {
    const dateStruct: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };

    return dateStruct;
  }

  onGetSubTotal() {
    let subtotal = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.detalles.length; index++) {
      const element = this.detalles[index];
      subtotal += element.Cantidad * element.Costo;
    }
    this.subtotal = subtotal;
    return subtotal;
  }

  OnUpdateTotal() {
    this.modeldetalle.Total = this.modeldetalle.Cantidad * this.modeldetalle.Costo;
  }

  onImprimir(id: number) {

  }

  async onProcesar(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que desea procesar la entrada, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Procesar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then(async (result) => {
      if (result.value) {
        const modelAut = {
          id
        };

        this._descomposicionService.procesar(modelAut)
        .subscribe(
          success => {
            Swal.fire({
              title: 'Procesada!',
              text: 'Entrada procesada con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
            this.onBuscar();
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
      }
    });
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.Codigo.toLocaleLowerCase().indexOf(term) > -1 ||
    item.Nombre.toLocaleLowerCase().indexOf(term) > -1 ||
    (item.Codigo + ' - ' + item.Nombre).toLocaleLowerCase().indexOf(term) > -1;
  }

}


import { Component, OnInit, ViewChild, TemplateRef, ContentChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../services/users/users.service';
import { Router } from '@angular/router';
import { ExistenciasService } from '../../services/existencias/existencias.service';
import { DatePipe } from '@angular/common';
import { Producto } from '../../models/Producto';
import { AclService } from 'ng2-acl/dist';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class HeaderComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  modalDevRef: BsModalRef;
  modalKardexGRef: BsModalRef;
  modalKardexPRef: BsModalRef;

  @ViewChild('templateExistencias') templateExistencias;
  @ViewChild('templateKardexGeneral') templateKardexGeneral;
  @ViewChild('templateKardexProducto') templateKardexProducto;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };
  configLarge = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'Custom-size-modal'
  };
  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  idproducto: any;
  idproductoK = 0;
  idModelo: any;
  idalmacen: any;
  fecha: NgbDateStruct;
  fechaKP: NgbDateStruct;
  isEnableInventario = false;
  isEnableAlmacen = false;
  loading = false;

  productosEK: any[] = [];
  almacenes: any[] = [];
  productosReg: any[] = [];
  existenciasProducto: any[] = [];
  existenciasModelos: any[] = [];

  constructor(private _userService: UsersService, private _existenciaService: ExistenciasService, private modalService: BsModalService, private toastr: ToastrService, public router: Router, public aclService: AclService) {
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.fecha = null;
    this.getCombos();
  }

  getCombos() {
    this._existenciaService.getCombos()
      .subscribe(
        data => {
          this.productosEK = data.productosEK;
          this.almacenes = data.almacenes;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  onProductoEKChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.idproducto = selectedValue.ID;
    this.idModelo = selectedValue.ID_ModeloProducto;

    const model = {
      idproducto: this.idproducto,
      idModelo: this.idModelo
    };
    this._existenciaService.getExistencia(model)
    .subscribe(
      success => {
        this.existenciasProducto = success.productos;
        this.existenciasModelos = success.modelos;
      },
      error => {
        this.toastr.error(error.message, 'Error!');
      });
  }

  onShowExistencia(){
    this.idproducto = null;
    this.idModelo = null;
    this.existenciasProducto = [];
    this.existenciasModelos = [];
    this.modalDevRef = this.modalService.show(this.templateExistencias, this.config);
  }

  onCerrarExistencia() {
    this.idproducto = null;
    this.idModelo = null;
    this.existenciasProducto = [];
    this.existenciasModelos = [];
    this.modalDevRef.hide();
  }

  onShowKardexGeneral() {
    this.fecha = null;
    this.idalmacen = null;
    this.isEnableInventario = false;
    this.isEnableAlmacen = false;
    this.loading = false;
    this.modalKardexGRef = this.modalService.show(this.templateKardexGeneral, this.config);
  }

  onCerrarKardexGeneral() {
    this.fecha = null;
    this.idalmacen = null;
    this.isEnableInventario = false;
    this.isEnableAlmacen = false;
    this.loading = false;
    this.modalKardexGRef.hide();
  }

  onEliminarKardex() {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que desea eliminar kardex?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then(async (result) => {
      if (result.value) {
        this._existenciaService.eliminarKardex()
        .subscribe(
          success => {
            this.isEnableInventario = true;
            this.toastr.success('Kardex eliminado con exito, puede proceder a reprocesar el kardex', 'Eliminado!');
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
      }
    });
  }

  onRegenerarKardexInvInicial() {
      if (this.fecha !== undefined && this.fecha !== null) {
        Swal.fire({
          title: 'Esta seguro Regenerar Kardex General Inventarios Inicial?',
          text: 'Esta seguro que desea regenerar kardex general inventarios inicial?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, Regenerar!',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        }).then(async (result) => {
          if (result.value) {
            this.loading = true;

            let fechaSet = '';

            if (this.fecha !== undefined ) {
                let datePipe = new DatePipe('en-US');
                fechaSet = datePipe.transform(new Date(this.fecha.year, this.fecha.month - 1, this.fecha.day) , 'MM/dd/yyyy');
            }

            const model = {
              fecha: fechaSet
            };
            this._existenciaService.inventarioInicialKardex(model)
            .subscribe(
              success => {
                this.isEnableAlmacen = true;
                this.isEnableInventario = false;
                this.loading = false;
                this.toastr.success('Inventarios Iniciales Reprocesados con existo', 'Generado!');
              },
              error => {
                this.loading = false;
                this.toastr.error(error.message, 'Error!');
              });
          }
        });
      } else {
        this.toastr.error('Favor de seleccionar fecha limite', 'Error!');
      }
  }

  onKardexGeneeral() {
      if (this.idalmacen > 0 && this.fecha !== undefined && this.fecha !== null) {
        Swal.fire({
          title: 'Esta seguro Regenerar Kardex General?',
          text: 'Esta seguro que desea regenerar kardex general?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, Regenerar!',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        }).then(async (result) => {
          if (result.value) {
            this.loading = true;

            let fechaSet = '';

            if (this.fecha !== undefined ) {
                let datePipe = new DatePipe('en-US');
                fechaSet = datePipe.transform(new Date(this.fecha.year, this.fecha.month - 1, this.fecha.day) , 'MM/dd/yyyy');
                // fechaSet = `${this.fecha.month}/${this.fecha.day}/${this.fecha.year}`;
            }

            const model = {
              idalmacen: this.idalmacen,
              fecha: fechaSet
            };
            this._existenciaService.regenerarKardexGeneral(model)
            .subscribe(
              success => {
                  this.toastr.success('Regeneracion de kardex con exito', 'Generado!');
                  this.loading = false;
                  this.idalmacen = null;
              },
              error => {
                this.toastr.error(error.message, 'Error!');
                this.loading = false;
              });
          }
        });
      } else {
        this.toastr.error('Favor de introducir campos necesarios', 'Error!');
      }
  }

  onShowKardexProducto() {
    this.idproductoK = null;
    this.productosReg = [];
    this.fechaKP = null;
    this.loading = false;
    this.modalKardexPRef = this.modalService.show(this.templateKardexProducto, this.config);
  }

  onCerrarKardexProducto() {
    this.idproductoK = null;
    this.productosReg = [];
    this.fechaKP = null;
    this.loading = false;
    this.modalKardexPRef.hide();
  }

  onDeleteKardexDetalle(model: Producto) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar detalle, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        const Index = this.productosReg.findIndex((Item: Producto) => Item.ID === model.ID);
        if (Index !== -1) {
            this.productosReg.splice(Index, 1);
        }
        Swal.fire({
          title: 'Eliminado!',
          text: 'Detalle a sido eliminado con exito.',
          type: 'success',
          confirmButtonText: 'Aceptar'
        });
      }
    });
  }

  onAgregarProducto() {
    const producto = this.productosEK.filter(
      item => item.ID === this.idproductoK
    )[0];

    const prod = <Producto>JSON.parse(JSON.stringify(producto));
    this.productosReg.push(prod);
    this.toastr.success('Producto agregado con exito.', 'Agregado!');
    this.idproductoK = null;
  }

  onKardexGeneralProducto() {
    if (this.productosReg.length > 0 && this.fechaKP !== undefined && this.fechaKP !== null) {
      Swal.fire({
        title: 'Esta seguro Regenerar Kardex Producto?',
        text: 'Esta seguro que desea regenerar kardex producto?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Regenerar!',
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;

          let fechaSet = '';

          if (this.fecha !== undefined ) {
              let datePipe = new DatePipe('en-US');
              fechaSet = datePipe.transform(new Date(this.fecha.year, this.fecha.month - 1, this.fecha.day) , 'MM/dd/yyyy');
              // fechaSet = `${this.fecha.month}/${this.fecha.day}/${this.fecha.year}`;
          }

          const model = {
            Productos: this.productosReg,
            fecha: fechaSet
          };
          this._existenciaService.regenerarKardexProducto(model)
          .subscribe(
            success => {
                this.toastr.success('Regeneracion de kardex con exito', 'Generado!');
                this.loading = false;
                this.onCerrarKardexProducto();
            },
            error => {
              this.toastr.error(error.message, 'Error!');
              this.loading = false;
            });
        }
      });
    } else {
      this.toastr.error('Favor de introducir campos necesarios', 'Error!');
    }
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.Codigo.toLocaleLowerCase().indexOf(term) > -1 ||
    item.Nombre.toLocaleLowerCase().indexOf(term) > -1 ||
    (item.Codigo + ' - ' + item.Nombre).toLocaleLowerCase().indexOf(term) > -1;
  }
}

import { Component, OnInit, ViewChild, TemplateRef, ContentChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../services/users/users.service';
import { Router } from '@angular/router';
import { ProductosService } from '../../services/productos/productos.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-generacion-productokit',
  templateUrl: './generacion-productokit.component.html',
  styleUrls: ['./generacion-productokit.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class GeneracionProductokitComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  modalDevRef: BsModalRef;

  @ViewChild('templateVenta') templateVenta;
  @ViewChild('templateDevolucion') templateDevolucion;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };
  configLarge = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'Custom-size-modal'
  };
  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  idalmacen: any;
  idproducto: any;
  cantidad = 0;
  observaciones = '';
  fecha: NgbDateStruct;
  detalles: any[] = [];
  costoUnitario = 0;
  costoUnitarioTotal = 0;

  productosNormal: any[] = [];
  productosKit: any[] = [];
  almacenes: any[] = [];

  total: number;

  constructor(private _userService: UsersService, private _productoService: ProductosService, private modalService: BsModalService, private toastr: ToastrService, public router: Router) {
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.fecha = {
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear()
    };
    this.getCombos();
  }

  onBuscar() {
    if (this.idalmacen > 0 && this.idproducto > 0 && this.cantidad > 0) {
      this._productoService.getListaProductoKitDetalles(this.idalmacen, this.idproducto, this.cantidad).subscribe(
        (data: any) => {
          this.detalles = data;
        },
        (error) => {
          Swal.fire({
            title: 'Error!',
            text: String(error.message),
            type: 'error',
            focusConfirm: false,
            focusCancel: false,
            allowEnterKey: false
          });
        }
      );
    } else {
      this.toastr.error('Favor de introducir campos necesarios', 'Error!');
    }
  }

  getCombos() {
    this._productoService.getCombosGenerarProductoKit()
      .subscribe(
        data => {
          this.productosNormal = data.productos;
          this.productosKit = data.productoskit;
          this.almacenes = data.almacenes;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  onSubmit(FormData: any) {
    if (FormData.valid) {
      if (this.idalmacen > 0 && this.idproducto > 0 && this.cantidad > 0) {
        Swal.fire({
          title: 'Esta seguro?',
          text: 'Esta seguro que desea guardar generar producto kit?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, Generar!',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        }).then(async (result) => {
          if (result.value) {
            let fechaSet = '';

            if (this.fecha !== undefined ) {
                let datePipe = new DatePipe('en-US');
                fechaSet = datePipe.transform(new Date(this.fecha.year, this.fecha.month - 1, this.fecha.day) , 'MM/dd/yyyy');
                // fechaSet = `${this.fecha.month}/${this.fecha.day}/${this.fecha.year}`;
            }

            const model = {
              idalmacen: this.idalmacen,
              idproducto: this.idproducto,
              cantidad: this.cantidad,
              observaciones: this.observaciones,
              fecha: fechaSet
            };
            this._productoService.generarProductoKit(model)
            .subscribe(
              success => {
                  this.toastr.success('Producto Kit generado con exito.', 'Generado!');
                  FormData.resetForm();
                  this.idalmacen = null;
                  this.idproducto = null;
                  this.cantidad = null;
                  this.observaciones = null;
                  this.fecha = null;
                  this.detalles = [];
                  this.costoUnitario = 0;
                  this.costoUnitarioTotal = 0;
                  this.fecha = {
                    day: new Date().getDate(),
                    month: new Date().getMonth() + 1,
                    year: new Date().getFullYear()
                  };
                  this.modalRef.hide();
              },
              error => {
                this.toastr.error(error.message, 'Error!');
              });
          }
        });
      } else {
        this.toastr.error('Favor de introducir campos necesarios', 'Error!');
      }
    }
  }

  onCerrar() {
    this.router.navigate(['/dashboard']);
  }

  onGetSubTotal() {
    let subtotal = 0;
    let subtotal2 = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.detalles.length; index++) {
      const element = this.detalles[index];
      subtotal += element.CantidadTotal * element.Costo;
      subtotal2 += element.CantidadUnitaria * element.Costo;
    }
    this.costoUnitario = subtotal2;
    this.costoUnitarioTotal = subtotal;
    return subtotal;
  }

  onProductoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.onBuscar();
  }

  OnUpdateTotal() {
    this.onBuscar();
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.Codigo.toLocaleLowerCase().indexOf(term) > -1 ||
    item.Nombre.toLocaleLowerCase().indexOf(term) > -1 ||
    (item.Codigo + ' - ' + item.Nombre).toLocaleLowerCase().indexOf(term) > -1;
  }
}





import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../services/users/users.service';
import { SolicitudAlmacen } from '../../models/SolicitudAlmacen';
import { SolicitudAlmacenDetalle } from '../../models/SolicitudAlmacenDetalle';
import { SolicitudAlmacenService } from '../../services/solicitudalmacen/solicitudalmacen.service';
import { PreOrdenDetalleDto } from '../../models/PreOrdenDetalle';
import { AclService } from 'ng2-acl/dist';


@Component({
  selector: 'app-solicitud-almacen',
  templateUrl: './solicitud-almacen.component.html',
  styleUrls: ['./solicitud-almacen.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class SolicitudAlmacenComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  modaldetalleRef: BsModalRef;
  modalordenRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };
  configLarge = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'Custom-size-modal'
  };
  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  lista: any[] = [];
  model: SolicitudAlmacen = new SolicitudAlmacen();
  modeldetalle: SolicitudAlmacenDetalle = new SolicitudAlmacenDetalle();
  detalles: SolicitudAlmacenDetalle[] = [];

  from: NgbDateStruct;
  to: NgbDateStruct;
  vendedor: any;
  cliente: any;
  sucursal: any;
  almacen: any;
  folio = '';
  vendedores: any[] = [];
  clientes: any[] = [];
  sucursales: any[] = [];
  almacenes: any[] = [];
  productos: any[] = [];
  productoskit: any[] = [];
  showguardar = true;
  radioProducto: any;
  activeNormal: any;
  activeModelo: any;
  activeKit: any;
  existencia: number;

  // Solicitud
  idSol: number;
  detallesOrden: PreOrdenDetalleDto[] = [];

  constructor(private _userService: UsersService, private _solicitudService: SolicitudAlmacenService, private modalService: BsModalService, private toastr: ToastrService, public aclService: AclService) { 
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.onBuscar();
    this.getCombos();
  }

  onBuscar() {
    let from = '';
    let to = ''; 
    if (this.from !== undefined && this.to !== undefined) {
      from = `${this.from.year}-${this.from.month}-${this.from.day}`;
      to = `${this.to.year}-${this.to.month}-${this.to.day}`;
    }

    this._solicitudService.getLista(from, to, this.cliente, this.vendedor, this.sucursal, this.almacen, this.folio).subscribe(
      (data: any) => {
        this.lista = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onSubmitSol(FormData: any) {
    if (FormData.valid) {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Esta seguro que quiere guardar Solicitud de Almacen.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Guardar!',
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false
      }).then((result) => {
        if (result.value) {
              this.model.ID_UltimoUsuario = this._userService.user.ID;
              if (this.model.Status === undefined || this.model.Status === null || this.model.Status === '') {
                this.model.Status = 'V';
              }

              if (this.model.ID_UsuarioRegistro === undefined || this.model.ID_UsuarioRegistro == null || this.model.ID_UsuarioRegistro == 0) {
                  this.model.ID_UsuarioRegistro = this._userService.user.ID;
              }

              const model = {
                model: this.model,
                detalles: this.detalles
              };
              this._solicitudService.guardar(model)
            .subscribe(
              success => {
                  this.toastr.success('Solicitud de Almacen guardado con exito.', 'Guardado!');
                  this.onBuscar();
                  FormData.resetForm();
                  this.modalRef.hide();
              },
              error => {
                this.toastr.error(error.message, 'Error!');
              });
        }
      });
    }
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new SolicitudAlmacen();
    this.detalles = [];
    this.showguardar = true;
    if (id <= 0) {
      this.modalRef = this.modalService.show(template, this.configLarge);
    } else {
      this._solicitudService.getSolicitud(id)
    .subscribe(
      data => {
        this.model = data.model;
        this.detalles = data.detalles;
        if (this.model.Fecha) {
          this.model.Fecha = new Date(this.model.Fecha);
          this.model.SelectedDate = this.getDateStructFromDate(this.model.Fecha);
        }

        if (this.model.CompEntrega) {
          this.model.CompEntrega = new Date(this.model.CompEntrega);
          this.model.SelectedEntregaDate = this.getDateStructFromDate(this.model.CompEntrega);
        }

        if (this.model.Status !== 'M') {
          this.showguardar = false;
         }

        this.modalRef = this.modalService.show(template, this.configLarge);
      },
      error => this.toastr.error(error.message, 'Error!') );
    }
  }

  onFechaSolChanged(selectedDate: NgbDateStruct) {
    this.model.Fecha = this.getDateFromDateStruct(selectedDate);
  }

  onFechaEntrChanged(selectedDate: NgbDateStruct) {
    this.model.CompEntrega = this.getDateFromDateStruct(selectedDate);
  }

  getCombos() {
    this._solicitudService.getCombos()
      .subscribe(
        data => {
          this.vendedores = data.vendedores;
          this.clientes = data.clientes;
          this.productos = data.productos;
          this.productoskit = data.productoskit;
          this.sucursales = data.sucursales;
          this.almacenes = data.almacenes;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  onShowSolDetalle(model: SolicitudAlmacenDetalle, template: TemplateRef<any>) {
    this.modeldetalle = new SolicitudAlmacenDetalle();
    if (model) {
      this.modeldetalle = model;
      if (model.ID_Producto && model.esKit === 'N') {
        this.radioProducto = 1;
      } else if (model.ID_Producto && model.esKit === 'Y') {
        this.radioProducto = 3;
      } else {
        this.radioProducto = 2;
      }

      this.onRadioProdChanged();
    } else {
      this.radioProducto = 2;
      this.onRadioProdChanged();
    }
    this.modaldetalleRef = this.modalService.show(template, this.configMd);
  }

  onRadioProdChanged() {
    if (this.radioProducto === 1) {
      this.activeNormal = true;
      this.activeModelo = false;
      this.activeKit = false;
      this.modeldetalle.esKit = 'N';
    } else if (this.radioProducto === 2) {
      this.activeNormal = false;
      this.activeModelo = true;
      this.activeKit = false;
      this.existencia = 0;
      this.modeldetalle.esKit = 'N';
    } else {
      this.activeNormal = false;
      this.activeModelo = false;
      this.activeKit = true;
      this.modeldetalle.esKit = 'Y';
    }
  }

  onDeleteSolDetalle(model: SolicitudAlmacenDetalle) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar detalle, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        const Index = this.detalles.findIndex((Item: SolicitudAlmacenDetalle) => Item.ID === model.ID);
        if (Index !== -1) {
            this.detalles.splice(Index, 1);
        }
        Swal.fire({
          title: 'Eliminado!',
          text: 'Detalle a sido eliminado con exito.',
          type: 'success',
          confirmButtonText: 'Aceptar'
        });
      }
    });
  }

  onSubmit(FormData: any) {
    if (FormData.valid) {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Esta seguro que quiere guardar Solicitud de Almacen.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Guardar!',
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false
      }).then((result) => {
        if (result.value) {
              this.model.ID_UltimoUsuario = this._userService.user.ID;
              if (this.model.Status === undefined || this.model.Status === null || this.model.Status === '') {
                this.model.Status = 'M';
              }

              if (this.model.ID_UsuarioRegistro === undefined || this.model.ID_UsuarioRegistro == null || this.model.ID_UsuarioRegistro == 0) {
                  this.model.ID_UsuarioRegistro = this._userService.user.ID;
              }

              const model = {
                model: this.model,
                detalles: this.detalles
              };
              this._solicitudService.guardar(model)
            .subscribe(
              success => {
                  this.toastr.success('Solicitud de Almacen guardado con exito.', 'Guardado!');
                  this.onBuscar();
                  FormData.resetForm();
                  this.modalRef.hide();
              },
              error => {
                this.toastr.error(error.message, 'Error!');
              });
        }
      });
    }
  }

  onSubmitDetalle(FormData: any) {
    if (FormData.valid) {
      this.modeldetalle.CantidadSurtir = this.modeldetalle.Cantidad;
      this.detalles.push(this.modeldetalle);
      this.toastr.success('Producto agregado con exito.', 'Agregado!');
      this.modaldetalleRef.hide();
    }
  }

  onProductoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.modeldetalle.InfoProducto = selectedValue.Nombre;
    this.modeldetalle.esKit = 'N';
    this.modeldetalle.ExistenciaActual = selectedValue.Cantidad;
    this.modeldetalle.ID_ModeloProducto = null;
  }

  onProductoKitChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.modeldetalle.InfoProducto = selectedValue.Nombre;
    this.modeldetalle.esKit = 'Y';
    this.modeldetalle.ExistenciaActual = selectedValue.Cantidad;
    this.modeldetalle.ID_ModeloProducto = null;
  }

  onGetClave(model: SolicitudAlmacenDetalle) {
    if (model.ID_Producto && model.esKit === 'N') {
        const producto = this.productos.filter(
          item => item.ID === model.ID_Producto
        )[0];
        return producto.Codigo;
    } else if (model.ID_Producto && model.esKit  === 'Y') {
      const producto = this.productoskit.filter(
        item => item.ID === model.ID_Producto
      )[0];
      return producto.Codigo;
    }
  }

  getDateFromDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  getDateStructFromDate(date: Date): NgbDateStruct {
    const dateStruct: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };

    return dateStruct;
  }

  onImprimir(id: number) {
    const model = {
      id
    };
    this._solicitudService.imprimir(model).subscribe(
        (data: any ) => {
          const fileblob = new Blob([data], {type: 'application/pdf'});
          if (navigator.appVersion.toString().indexOf('.NET') > 0) {
              window.navigator.msSaveOrOpenBlob(fileblob, 'CoverSheet.pdf');
          } else {
              const fileURL = URL.createObjectURL(fileblob);
              window.open(fileURL);
          }
        },
        (error) => {
          this.toastr.error(error.message, 'Error!');
        }
      );
  }

  async onAutorizar(id: number) {
    const { value: formValues } = await Swal.fire({
      title: 'Autorización',
      html:
        'Usuario: <br> <input id="swal-input1" class="swal2-input">' +
        'Contraseña: <br> <input type="password" id="swal-input2" class="swal2-input">',
      focusConfirm: false,
      preConfirm: () => {
        return [
          (<any>document.getElementById('swal-input1')).value,
          (<any>document.getElementById('swal-input2')).value
        ];
      }
    });

    if (formValues) {
      let us = formValues[0];
      let pass = formValues[1];

      const modelAut = {
        usuario: us,
        password: pass,
        id: id
      };

      this._solicitudService.autorizar(modelAut)
      .subscribe(
        success => {
          Swal.fire({
            title: 'Autorizada!',
            text: 'Solicitud autorizada con exito.',
            type: 'success',
            confirmButtonText: 'Aceptar'
          });
          this.onBuscar();
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }
  }

  onCancelar(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que desea cancelar la Solicitud, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Cancelar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then(async (result) => {
      if (result.value) {
        const { value: formValues } = await Swal.fire({
          title: 'Autorización - Cancelar',
          html:
            'Usuario: <br> <input id="swal-input1" class="swal2-input">' +
            'Contraseña: <br> <input type="password" id="swal-input2" class="swal2-input">',
          focusConfirm: false,
          preConfirm: () => {
            return [
              (<any>document.getElementById('swal-input1')).value,
              (<any>document.getElementById('swal-input2')).value
            ];
          }
        });
    
        if (formValues) {
          let us = formValues[0];
          let pass = formValues[1];
    
          const modelAut = {
            usuario: us,
            password: pass,
            id: id
          };
    
          this._solicitudService.cancelar(modelAut)
          .subscribe(
            success => {
              Swal.fire({
                title: 'Cancelada!',
                text: 'Solicitud de almacen ha sido cancelada con exito.',
                type: 'success',
                confirmButtonText: 'Aceptar'
              });
              this.onBuscar();
            },
            error => {
              this.toastr.error(error.message, 'Error!');
            });
        }
      }
    });
  }

  async onSurtir(id: number) {
    const { value: formValues } = await Swal.fire({
      title: 'Autorización - Surtir',
      html:
        'Usuario: <br> <input id="swal-input1" class="swal2-input">' +
        'Contraseña: <br> <input type="password" id="swal-input2" class="swal2-input">',
      focusConfirm: false,
      preConfirm: () => {
        return [
          (<any>document.getElementById('swal-input1')).value,
          (<any>document.getElementById('swal-input2')).value
        ];
      }
    });

    if (formValues) {
      let us = formValues[0];
      let pass = formValues[1];

      const modelAut = {
        usuario: us,
        password: pass,
        id: id
      };

      this._solicitudService.surtir(modelAut)
      .subscribe(
        success => {
          Swal.fire({
            title: 'Surtida!',
            text: 'Solicitud de almacen ha sido surtida con exito.',
            type: 'success',
            confirmButtonText: 'Aceptar'
          });
          this.onBuscar();
        },
        error => {
          this.toastr.error(error.message, 'Error!');
        });
    }
  }

  // Orden de compra
  onShowGenerar(id: number, template: TemplateRef<any>) {
    this.idSol = id;
    this.detallesOrden = [];

    this._solicitudService.getGenerarOrdenSolicitud(id)
    .subscribe(
      data => {
        this.detallesOrden = data.preOrdenDetalleDtos;
        this.modalordenRef = this.modalService.show(template, this.configLarge);
      },
      error => this.toastr.error(error.message, 'Error!') );
  }

  onSubmitOrden(FormData: any) {
    if (FormData.valid) {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Esta seguro que quiere generar orden de compra.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Guardar!',
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false
      }).then((result) => {
        if (result.value) {

              const model = {
                id: this.idSol,
                detalles: this.detallesOrden
              };
              this._solicitudService.guardarOrden(model)
            .subscribe(
              success => {
                  this.toastr.success('Orden de compra guardado con exito.', 'Guardado!');
                  this.onBuscar();
                  FormData.resetForm();
                  this.modalordenRef.hide();
              },
              error => {
                this.toastr.error(error.message, 'Error!');
              });
        }
      });
    }
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.Codigo.toLocaleLowerCase().indexOf(term) > -1 || 
    item.Nombre.toLocaleLowerCase().indexOf(term) > -1 || 
    (item.Codigo + ' - ' + item.Nombre).toLocaleLowerCase().indexOf(term) > -1;
  }
}

export class Cliente {
  ID: number;
  Nombre: string;
  RazonSocial: string;
  Rfc: string;
  Contacto: string;
  TelefonoContacto: string;
  Direccion: string;
  Referencia: string;
  Colonia: string;
  CodigoPostal: string;
  Ciudad: string;
  Estado: string;
  Telefono: string;
  Email: string;
  MetodoPago: string;
  Credito: string;
  DiasCredito: number;
  FechaAlta?: any;
  Status: string;
  ID_VendedorAsignado: number;
  ID_UsuarioModificador: number;
  Observaciones: string;
  NumeroClienteInterno: string;
  ID_FormaPago: number;
  Municipio: string;
}

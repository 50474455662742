import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { Producto } from 'src/app/models/Producto';
import { ProductosService } from '../../services/productos/productos.service';
import { UsersService } from '../../services/users/users.service';
import { ProductoKit } from 'src/app/models/ProductoKit';
import { AclService } from 'ng2-acl/dist';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  modalListaKitsRef: BsModalRef;
  modalAgregarKitRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };
  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  public toastconfig: any = { timeOut: 0, extendedTimeOut: 0, preventDuplicates: true, maxOpened: 1, autoDismiss: false };
  productoFilter: string = '';
  productos: any[] = [];
  productosDetalle: any[] = [];
  model: Producto = new Producto();
  kits: ProductoKit[] = [];
  modelKit: ProductoKit = new ProductoKit();
  proveedores: any[] = [];
  lineasproveedor: any[] = [];
  lineasproveedoreFilter: any[] = [];
  modelos: any[] = [];
  modelosFilter: any[] = [];
  unidades: any[] = [];
  lineas: any[] = [];
  sublineas: any[] = [];
  sublineasFilter: any[] = [];
  idLineaProveedor:number;
  idLinea:number;
  eskit = false;
  inventariable = false;
  serial = false;
  activo = false;
  existencia = 0;

  public imagePath;
  imgURL: any;
  public message: string;

  constructor(private _userService: UsersService, private _productoService: ProductosService, private modalService: BsModalService, private toastr: ToastrService, public aclService: AclService) { 
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.imgURL = 'assets/images/default-upload.png';
    this.onBuscar();
    this.getCombos();
  }

  onBuscar() {
    this._productoService.getLista(this.productoFilter).subscribe(
      (data: any) => {
        this.productos = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onSubmit(FormData: any) {
    if (FormData.valid) {
      if (this.imgURL !== 'assets/images/default-upload.png' && this.imgURL !== this.model.RutaImagen) {
        this.model.RutaImagen = this.imgURL;
      }

      if ( this.inventariable ) {
        this.model.Inventariable = 'Y';
      } else {
        this.model.Inventariable = 'N';
      }

      if (this.serial) {
        this.model.esSerialprd = 'Y';
      } else {
        this.model.esSerialprd = 'N';
      }

      if (this.eskit) {
        this.model.esKit = 'Y';
      } else {
        this.model.esKit = 'N';
      }

      const data = {
        producto: this.model,
        productoskit: this.kits
      };

      this._productoService.guardar(data)
    .subscribe(
      success => {
          this.toastr.success('Producto guardado con exito.', 'Guardado!');
          this.onBuscar();
          FormData.resetForm();
          this.modalRef.hide();
      },
      error => {
        this.toastr.error(error.message, 'Error!');
      });
    }
  }

  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar producto, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        this._productoService.eliminar(id)
        .subscribe(
          success => {
            this.onBuscar();
            Swal.fire({
              title: 'Eliminado!',
              text: 'Producto a sido eliminado con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
      }
    });
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new Producto();
    this.eskit = false;
    this.inventariable = false;
    this.serial = false;
    this.activo = false;
    this.kits = [];
    this.idLineaProveedor = null;
    this.idLinea = null;
    if (id <= 0) {
      this.activo = true;
      this.modalRef = this.modalService.show(template, this.config);
    } else {
      this._productoService.getProducto(id)
    .subscribe(
      data => {
        this.model = data.producto;
        if (this.model.RutaImagen !== '') {
          this.imagePath = this.model.RutaImagen;
        }

        if (this.model.Inventariable === 'Y') {
          this.inventariable = true;
        } else {
          this.inventariable = false;
        }

        if (this.model.esSerialprd === 'Y') {
          this.serial = true;
        } else {
          this.serial = false;
        }

        if (this.model.esKit === 'Y') {
          this.eskit = true;
        } else {
          this.eskit = false;
        }

        this.lineasproveedoreFilter = this.lineasproveedor.filter(
          item => item.ID_Proveedor === this.model.ID_Proveedor
        );

        let modelo = this.modelos.filter(
          item => item.ID === this.model.ID_ModeloProducto
        )[0];

        this.idLineaProveedor = modelo.ID_Linea_Proveedor;
        this.modelosFilter = this.modelos.filter(
          item => item.ID_Linea_Proveedor === modelo.ID_Linea_Proveedor
        );

        let sublinea = this.sublineas.filter(
          item => item.ID === this.model.ID_SubLinea
        )[0];

        this.idLinea = sublinea.ID_Linea;
        this.sublineasFilter = this.sublineas.filter(
          item => item.ID_Linea === this.idLinea
        );

        this.kits = data.kit;
        if (this.model.RutaImagen !== '') {
          this.imgURL = this.model.RutaImagen;
        }
        this.modalRef = this.modalService.show(template, this.config);
      },
      error => this.toastr.error(error.message, 'Error!') );
    }
  }

  onProveedorChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }

    this.lineasproveedoreFilter = this.lineasproveedor.filter(
      item => item.ID_Proveedor === selectedValue.ID
    );
    this.idLineaProveedor = null;
    this.model.ID_ModeloProducto = null;
  }

  onLineaProveedorChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.idLineaProveedor = selectedValue.ID;
    this.modelosFilter = this.modelos.filter(
      item => item.ID_Linea_Proveedor === selectedValue.ID
    );
  }

  onLineaChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.model.ID_SubLinea = null;
    this.sublineasFilter = this.sublineas.filter(
      item => item.ID_Linea === selectedValue.ID
    );
  }

  getCombos() {
    this._productoService.getCombos()
      .subscribe(
        data => {
          this.proveedores = data.proveedores;
          this.lineasproveedor = data.proveedorLineas;
          this.modelos = data.modelos;
          this.unidades = data.unidades;
          this.lineas = data.lineas;
          this.sublineas = data.sublineas;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  getProductos() {
    this._productoService.getProductos()
      .subscribe(
        data => {
          this.productosDetalle = data;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    // tslint:disable-next-line: variable-name
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }

  onShowDetalle(model: ProductoKit, template: TemplateRef<any>) {
    this.modelKit = new ProductoKit();
    this.existencia = 0;
    if (model) {
      this.modelKit = model;
    }
    this.modalAgregarKitRef = this.modalService.show(template, this.configMd);
  }

  onShowKits(template: TemplateRef<any>) {
    this.getProductos();
    this.modalListaKitsRef = this.modalService.show(template, this.config);
  }

  onDeleteDetalle(model: ProductoKit) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar componente, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        const Index = this.kits.findIndex((Item: ProductoKit) => Item.ID === model.ID);
        if (Index !== -1) {
            this.kits.splice(Index, 1);
        }
        Swal.fire({
          title: 'Eliminado!',
          text: 'Componente a sido eliminado con exito.',
          type: 'success',
          confirmButtonText: 'Aceptar'
        });
      }
    });
  }

  onProductoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }

    this.modelKit.Nombre = selectedValue.Nombre;
    this.modelKit.Clave = selectedValue.Codigo;
    this.existencia = selectedValue.Cantidad;
  }

  onSubmitDetalle(FormData: any) {
    if (FormData.valid) {
      this.kits.push(this.modelKit);
      this.toastr.success('Producto agregado con exito.', 'Agregado!');
      this.modalAgregarKitRef.hide();
    }
  }
}

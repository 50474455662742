import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Modelo } from '../../models/Modelo';
import { ModelosService } from '../../services/modelos/modelos.service';
import { UsersService } from '../../services/users/users.service';
import { AclService } from 'ng2-acl/dist';

@Component({
  selector: 'app-modelos',
  templateUrl: './modelos.component.html',
  styleUrls: ['./modelos.component.css']
})
export class ModelosComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };
  public toastconfig: any = { timeOut: 0, extendedTimeOut: 0, preventDuplicates: true, maxOpened: 1, autoDismiss: false };
  modeloFilter: string = '';
  modelos: any[] = [];
  model: Modelo = new Modelo();
  proveedores: any[] = [];
  lineasproveedore: any[] = [];
  lineasproveedorefilter: any[] = [];
  public imagePath;
  imgURL: any;
  public message: string;
  idProveedor;

  constructor(private _userService: UsersService, private _modeloService: ModelosService, private modalService: BsModalService, private toastr: ToastrService, public aclService: AclService) { 
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.onBuscar();
    this.getProveedores();
    this.getLineasProveedores();
    this.imgURL = 'assets/images/default-upload.png';
  }

  onBuscar() {
    this._modeloService.getLista(this.modeloFilter).subscribe(
      (data: any) => {
        this.modelos = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onSubmit(FormData:any, files:any) {
    if (FormData.valid) {
      if (this.imgURL !== 'assets/images/default-upload.png' && this.imgURL !== this.model.RutaImagen) {
        this.model.RutaImagen = this.imgURL;
      }

      this._modeloService.guardar(this.model)
    .subscribe(
      success => {
          this.toastr.success('Modelos de producto guardado con exito.', 'Guardado!');
          this.onBuscar();
          FormData.resetForm();
          this.modalRef.hide();
        // if (files != null && files.length > 0 && success.ID > 0) {
        //   this.toastr.success('Modelos de producto guardado con exito.', 'Guardado!');

        //   var parameters = {
        //       Id: success.ID
        //   };

        //   this._modeloService.uploadImage(files, parameters).subscribe(
        //     success => {
        //       this.toastr.success('Imagen de Modelos de producto guardado con exito.', 'Guardado!');
        //     },
        //     error => {
        //       this.toastr.error(error.message, 'Error!');
        //     });
        //     this.onBuscar();
        //     FormData.resetForm();
        //     this.modalRef.hide();
        // } else {
        // }
      },
      error => {
        this.toastr.error(error.message, 'Error!');
      });
    }
  }

  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar modelo de producto, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        this._modeloService.eliminar(id)
        .subscribe(
          success => {
            this.onBuscar();
            Swal.fire({
              title: 'Eliminado!',
              text: 'Modelo de producto a sido eliminado con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
      }
    });
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new Modelo();
    if (id <= 0) {
      this.idProveedor = this.proveedores[0].ID;
      this.lineasproveedorefilter = this.lineasproveedore.filter(
        item => item.ID_Proveedor === this.idProveedor
      );

      this.modalRef = this.modalService.show(template, this.config);
    } else {
      this._modeloService.getModelo(id)
    .subscribe(
      data => {
        this.idProveedor = this.lineasproveedore.filter(
          item => item.ID === data.ID_Linea_Proveedor
        )[0].ID_Proveedor;

        this.lineasproveedorefilter = this.lineasproveedore.filter(
          item => item.ID_Proveedor === this.idProveedor
        );

        this.model = data;
        if (this.model.RutaImagen !== '') {
          this.imagePath = this.model.RutaImagen;
        }
        this.modalRef = this.modalService.show(template, this.config);
      },
      error => this.toastr.error(error.message, 'Error!') );
    }
  }

  getProveedores() {
    this._modeloService.getProveedores()
      .subscribe(
        data => {
          this.proveedores = data;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  getLineasProveedores() {
    this._modeloService.getLineasProveedor()
      .subscribe(
        data => {
          this.lineasproveedore = data;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  onProveedorChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }

    this.lineasproveedorefilter = this.lineasproveedore.filter(
      item => item.ID_Proveedor === selectedValue.ID
    );
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    // tslint:disable-next-line: variable-name
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      this.model.RutaImagen = this.imgURL;
    };
  }
}

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../services/users/users.service';
import { EntradaService } from '../../services/entradas/entrada.service';
import { Entrada } from '../../models/Entrada';
import { EntradaDetalle } from '../../models/EntradaDetalle';
import { AclService } from 'ng2-acl/dist';

@Component({
  selector: 'app-entradas',
  templateUrl: './entradas.component.html',
  styleUrls: ['./entradas.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class EntradasComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  modaldetalleRef: BsModalRef;
  modalordenRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };
  configLarge = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'Custom-size-modal'
  };
  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  lista: any[] = [];
  model: Entrada = new Entrada();
  modeldetalle: EntradaDetalle = new EntradaDetalle();
  detalles: EntradaDetalle[] = [];

  from: NgbDateStruct;
  to: NgbDateStruct;
  tipo: any;
  almacenid: any;
  folio = '';
  folioentrada = '';

  almacenes: any[] = [];
  tipos: any[] = [];
  productos: any[] = [];
  productosNormal: any[] = [];
  showguardar = true;

  constructor(private _userService: UsersService, private _entradaService: EntradaService, private modalService: BsModalService, private toastr: ToastrService, public aclService: AclService) {
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.onBuscar();
    this.getCombos();
  }

  onBuscar() {
    let from = '';
    let to = '';
    if (this.from !== undefined && this.to !== undefined) {
      from = `${this.from.year}-${this.from.month}-${this.from.day}`;
      to = `${this.to.year}-${this.to.month}-${this.to.day}`;
    }

    this._entradaService.getLista(from, to, this.tipo, this.almacenid, this.folioentrada, this.folio).subscribe(
      (data: any) => {
        this.lista = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onSubmit(FormData: any) {
    if (FormData.valid) {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Esta seguro que quiere guardar Entrada.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Guardar!',
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false
      }).then((result) => {
        if (result.value) {
              this.model.ID_Usuario = this._userService.user.ID;
              if (this.model.Status === undefined || this.model.Status === null || this.model.Status === '') {
                this.model.Status = 'G';
              }

              const model = {
                model: this.model,
                detalles: this.detalles
              };
              this._entradaService.guardar(model)
            .subscribe(
              success => {
                  this.toastr.success('Entrada guardado con exito.', 'Guardado!');
                  this.onBuscar();
                  FormData.resetForm();
                  this.modalRef.hide();
              },
              error => {
                this.toastr.error(error.message, 'Error!');
              });
        }
      });
    }
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new Entrada();
    this.detalles = [];
    this.showguardar = true;
    if (id <= 0) {
      this.modalRef = this.modalService.show(template, this.configLarge);
    } else {
      this._entradaService.getEntrada(id)
    .subscribe(
      data => {
        this.model = data.model;
        this.detalles = data.detalles;
        if (this.model.Fecha) {
          this.model.Fecha = new Date(this.model.Fecha);
          this.model.SelectedDate = this.getDateStructFromDate(this.model.Fecha);
        }

        if (this.model.Status === 'C') {
          this.showguardar = false;
         }

        this.modalRef = this.modalService.show(template, this.configLarge);
      },
      error => this.toastr.error(error.message, 'Error!') );
    }
  }

  onFechaSolChanged(selectedDate: NgbDateStruct) {
    this.model.Fecha = this.getDateFromDateStruct(selectedDate);
  }

  getCombos() {
    this._entradaService.getCombos()
      .subscribe(
        data => {
          this.tipos = data.tipos;
          this.productos = data.productos;
          this.almacenes = data.almacenes;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  onShowSolDetalle(model: EntradaDetalle, template: TemplateRef<any>) {
    this.modeldetalle = new EntradaDetalle();
    if (model) {
      this.modeldetalle = model;
    } 
    this.modaldetalleRef = this.modalService.show(template, this.configMd);
  }

  onDeleteSolDetalle(model: EntradaDetalle) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar detalle, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        const Index = this.detalles.findIndex((Item: EntradaDetalle) => Item.ID === model.ID);
        if (Index !== -1) {
            this.detalles.splice(Index, 1);
        }
        Swal.fire({
          title: 'Eliminado!',
          text: 'Detalle a sido eliminado con exito.',
          type: 'success',
          confirmButtonText: 'Aceptar'
        });
      }
    });
  }


  onSubmitDetalle(FormData: any) {
    if (FormData.valid) {
      this.modeldetalle.Descuento = 0;
      this.modeldetalle.Importe = this.modeldetalle.Cantidad * this.modeldetalle.CostoUnitario;
      this.detalles.push(this.modeldetalle);
      this.toastr.success('Producto agregado con exito.', 'Agregado!');
      this.modaldetalleRef.hide();
    }
  }

  onProductoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.modeldetalle.CostoUnitario = selectedValue.Costo;
    this.modeldetalle.Descuento = 0;
    this.modeldetalle.ID_Proveedor  = selectedValue.ID_Proveedor;
  }


  onGetClave(model: EntradaDetalle) {
    const producto = this.productos.filter(
      item => item.ID === model.ID_Producto
    )[0];
    return producto.Codigo;
  }

  onGetInfoProducto(model: EntradaDetalle) {
    const producto = this.productos.filter(
      item => item.ID === model.ID_Producto
    )[0];
    return producto.Nombre;
  }

  getDateFromDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  getDateStructFromDate(date: Date): NgbDateStruct {
    const dateStruct: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };

    return dateStruct;
  }

  onImprimir(id: number) {
    const model = {
      id
    };
    this._entradaService.imprimir(model).subscribe(
        (data: any ) => {
          const fileblob = new Blob([data], {type: 'application/pdf'});
          if (navigator.appVersion.toString().indexOf('.NET') > 0) {
              window.navigator.msSaveOrOpenBlob(fileblob, 'CoverSheet.pdf');
          } else {
              const fileURL = URL.createObjectURL(fileblob);
              window.open(fileURL);
          }
        },
        (error) => {
          this.toastr.error(error.message, 'Error!');
        }
      );
  }

  async onProcesar(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que desea procesar la entrada, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Procesar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then(async (result) => {
      if (result.value) {
        const modelAut = {
          id
        };

        this._entradaService.procesar(modelAut)
        .subscribe(
          success => {
            Swal.fire({
              title: 'Procesada!',
              text: 'Entrada procesada con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
            this.onBuscar();
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });
      }
    });
  }

  onCancelar(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que desea cancelar la entrada, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Cancelar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then(async (result) => {
      if (result.value) {

        const modelAut = {
          id
        };

        this._entradaService.cancelar(modelAut)
        .subscribe(
          success => {
            Swal.fire({
              title: 'Cancelada!',
              text: 'Entrada cancelada con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
            this.onBuscar();
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });

      }
    });
  }

  onEliminar(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que desea eliminar la entrada, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then(async (result) => {
      if (result.value) {

        const modelAut = {
          id
        };

        this._entradaService.eliminar(modelAut)
        .subscribe(
          success => {
            Swal.fire({
              title: 'Eliminada!',
              text: 'Entrada eliminar con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
            this.onBuscar();
          },
          error => {
            this.toastr.error(error.message, 'Error!');
          });

      }
    });
  }

  OnUpdateTotal() {
    this.modeldetalle.Descuento = 0;
    this.modeldetalle.Importe = this.modeldetalle.Cantidad * this.modeldetalle.CostoUnitario;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.Codigo.toLocaleLowerCase().indexOf(term) > -1 ||
    item.Nombre.toLocaleLowerCase().indexOf(term) > -1 ||
    (item.Codigo + ' - ' + item.Nombre).toLocaleLowerCase().indexOf(term) > -1;
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

// Modules
import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages-routing.module';

// Directives
import { DatepickerToggleDirective } from '../directives/datepicker-toggle.directive';

// Routes
import { DashboardComponent } from './dashboard/dashboard.component';
import { SucursalesComponent } from './sucursales/sucursales.component';
import { AlmacenesComponent } from './almacenes/almacenes.component';
import { ClientesComponent } from './clientes/clientes.component';
import { ModelosComponent } from './modelos/modelos.component';
import { ProductosComponent } from './productos/productos.component';
import { TiposEntradaSalidaComponent } from './tipos-entrada-salida/tipos-entrada-salida.component';
import { ProveedoresComponent } from './proveedores/proveedores.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { TiposUsuarioComponent } from './tipos-usuario/tipos-usuario.component';
import { LineasProveedorComponent } from './lineas-proveedor/lineas-proveedor.component';
import { LineasProductoComponent } from './lineas-producto/lineas-producto.component';
import { SublineasProductoComponent } from './sublineas-producto/sublineas-producto.component';
import { UnidadMedidaComponent } from './unidad-medida/unidad-medida.component';
import { ExistenciasComponent } from './existencias/existencias.component';
import { MergeProductoComponent } from './merge-producto/merge-producto.component';
import { DescomposicionProductoComponent } from './descomposicion-producto/descomposicion-producto.component';
import { GeneracionProductokitComponent } from './generacion-productokit/generacion-productokit.component';
import { InventarioFisicoComponent } from './inventario-fisico/inventario-fisico.component';
import { TiposESComponent } from './tipos-es/tipos-es.component';
import { EntradasComponent } from './entradas/entradas.component';
import { SalidasComponent } from './salidas/salidas.component';
import { TraspasosComponent } from './traspasos/traspasos.component';
import { RecepcionTraspasosComponent } from './recepcion-traspasos/recepcion-traspasos.component';
import { ComodatosComponent } from './comodatos/comodatos.component';
import { ComodatoMultipleComponent } from './comodato-multiple/comodato-multiple.component';
import { RetirosComponent } from './retiros/retiros.component';
import { RemisionVentasComponent } from './remision-ventas/remision-ventas.component';
import { SolicitudAlmacenComponent } from './solicitud-almacen/solicitud-almacen.component';
import { OrdenComprasComponent } from './orden-compras/orden-compras.component';
import { ComprasComponent } from './compras/compras.component';
import { VentasComponent } from './ventas/ventas.component';
import { CotizacionesComponent } from './cotizaciones/cotizaciones.component';
import { ReportesComponent } from './reportes/reportes.component';

@NgModule({
    declarations: [
        DashboardComponent,
        SucursalesComponent,
        AlmacenesComponent,
        ClientesComponent,
        ModelosComponent,
        ProductosComponent,
        TiposEntradaSalidaComponent,
        ProveedoresComponent,
        DatepickerToggleDirective,
        UsuariosComponent,
        TiposUsuarioComponent,
        LineasProveedorComponent,
        LineasProductoComponent,
        SublineasProductoComponent,
        UnidadMedidaComponent,
        ExistenciasComponent,
        MergeProductoComponent,
        DescomposicionProductoComponent,
        GeneracionProductokitComponent,
        InventarioFisicoComponent,
        TiposESComponent,
        EntradasComponent,
        SalidasComponent,
        TraspasosComponent,
        RecepcionTraspasosComponent,
        ComodatosComponent,
        ComodatoMultipleComponent,
        RetirosComponent,
        RemisionVentasComponent,
        SolicitudAlmacenComponent,
        OrdenComprasComponent,
        ComprasComponent,
        VentasComponent,
        CotizacionesComponent,
        ReportesComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        PagesRoutingModule,
        NgSelectModule,
        NgOptionHighlightModule,
        NgbDatepickerModule
    ],
    exports: [
        DashboardComponent
    ]
})
export class PagesModule {}

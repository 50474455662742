import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class SolicitudAlmacen {
  ID: number;
  Fecha: any;
  Observaciones: string;
  Status: string;
  ID_UsuarioRegistro: number;
  ID_Vendedor: number;
  ID_UltimoUsuario: number;
  ID_Cliente: number;
  ID_Cotizacion?: number;
  ID_Almacen?: number;
  FolioSolicitud: string;
  ID_UsuarioAutorizo: number;
  LugarEntrega: string;
  Atencion: string;
  CompEntrega: any;
  Anticipo: number;
  OCCliente: string;
  SelectedDate: NgbDateStruct;
  SelectedEntregaDate: NgbDateStruct;
  constructor() {
    this.ID = 0;
    this.Observaciones = '';
    this.Fecha = new Date();
    this.SelectedDate = {
        day: this.Fecha.getDate(),
        month: this.Fecha.getMonth() + 1,
        year: this.Fecha.getFullYear() };
    this.SelectedEntregaDate = {
        day: this.Fecha.getDate(),
        month: this.Fecha.getMonth() + 1,
        year: this.Fecha.getFullYear() };
  }
}
